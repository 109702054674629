import $api from '@/http'

async function getList(pageNo, pageSize, filterModel) {
  return await $api.get('expert/list', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      keyword: filterModel.keyword
    }
  })
}

async function create(datas) {
  return await $api.post('expert/create', datas)
}

async function update(datas) {
  return await $api.patch('expert/update', datas)
}

async function getById(id) {
  return await $api.get('expert/getById/' + id)
}

async function saveFiles(id, formData) {
  return await $api.patch(`expert/saveFiles/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
async function getExpertFiles(expertId ) {
  return await $api.get('expert/files', {
    params: {
      expertId
    }
  })
}

async function deleteFile(id, expertFileId) {
  return await $api.delete(`expert/deleteFile`, {
    params: {
      id, expertFileId
    }
  })
}
export default {
  getList,
  create,
  update,
  getById,
  saveFiles,
  getExpertFiles,
  deleteFile
}
