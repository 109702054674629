/* eslint-disable */
import AuthService from '../services/AuthService';

const userFromLS = JSON.parse(localStorage.getItem('user'));
const jwtToken = JSON.parse(localStorage.getItem('jwtToken'));
const token = JSON.parse(localStorage.getItem('token'));
const initialState = userFromLS
  ? { status: { loggedIn: true }, user: userFromLS, jwtToken: jwtToken, token: token }
  : { status: { loggedIn: false }, user: null, jwtToken: null, token: null };

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit, state }) {
      if (!state.refreshPromise) {
        state.refreshPromise = AuthService.refreshToken(state.token)
          .then(response => {
            commit('loginSuccess', response);
            return response;
          })
          .catch(error => {
            commit('loginFailure');
            return Promise.reject(error);
          })
          .finally(() => {
            state.refreshPromise = null;
          });
      }
      return state.refreshPromise;
    },
    logout({ commit }) {
      AuthService.logout(initialState.token).then(() => {
        commit('logout');
      });
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      localStorage.setItem('user', JSON.stringify(user.userInfo));
      localStorage.setItem('jwtToken', JSON.stringify(user.jwtToken));
      localStorage.setItem('token', JSON.stringify(user.token));
      state.user = user.userInfo;
      state.jwtToken = user.jwtToken;
      state.token = user.token;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.jwtToken = null;
      state.token = null;
      localStorage.removeItem('user');
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('token');
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.jwtToken = null;
      state.token = null;
      localStorage.removeItem('user');
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('token');
    },
  },
};
