import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import auth from './auth-module'
let url = ''
if (window.location.hostname === 'localhost') {
  url = 'http://localhost:8081/'
}
if (window.location.hostname === 'ms.med.kg') {
  url = 'https://msback.med.kg/'
}
if (window.location.hostname === 'mctest.med.kg') {
  url = 'https://mctestback.med.kg/'
}

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    auth
  },
  state: {
    API_URI: url
  }
})

export function useStore() {
  return store
}

export default store
