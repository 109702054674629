import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import 'sweetalert2/dist/sweetalert2.min.css'
import $ from 'jquery'
import 'mosha-vue-toastify/dist/style.css'

// SASS Theme
import './assets/sass/app.scss'
import './assets/css/main.css'

const app = createApp(App)
  .use(store)
  .use(router)


globalComponents(app)
utils(app)

app.mount('#app')

