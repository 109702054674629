/* eslint-disable */
import {createRouter, createWebHistory} from 'vue-router'
import Profile from '../views/users/profile/Main.vue'
import store from '@/store'


const routes = [
  {
    path: '/',
    component: () => import('@/layouts/side-menu/Main.vue'),
    children: [
      {
        path: 'userroles',
        name: 'userroles',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/users/userRole/Main.vue')
      },
      {
        path: 'users',
        name: 'users',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/users/user/Main.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile
      },
      {
        path: 'accessControl',
        name: 'accessControl',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/users/accessControl/Main.vue')
      },
      //DICTIONARIES
      {
        path: 'dictionaries/country',
        name: 'Country',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/dictionaries/country/Main.vue')
      },
      {
        path: 'dictionaries/sectorCategory',
        name: 'SectorCategory',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/dictionaries/sectorCategory/Main.vue')
      },
      {
        path: 'dictionaries/sector',
        name: 'Sector',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/dictionaries/sector/Main.vue')
      },
      {
        path: 'dictionaries/language',
        name: 'Language',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/dictionaries/language/Main.vue')
      },
      {
        path: 'dictionaries/originOfFunding',
        name: 'OriginOfFunding',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/dictionaries/originOfFunding/Main.vue')
      },
      {
        path: 'dictionaries/nationality',
        name: 'Nationality',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/dictionaries/nationality/Main.vue')
      },
      // MAIN PAGES
      {
        path: 'expert/list',
        name: 'Experts',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/main/expert/List.vue')
      },
      {
        path: 'expert/form',
        name: 'ExpertForm',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/main/expert/AddForm.vue')
      },
      {
        path: 'reference/form',
        name: 'ReferenceForm',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/main/reference/AddForm.vue')
      },
      {
        path: 'reference/list',
        name: 'References',
        permission: 'SUPER_ADMIN',
        component: () => import('@/views/main/reference/List.vue')
      },
    ]
  },
  {
    path: '/simple-menu',
    component: () => import('@/layouts/simple-menu/Main.vue'),
    children: []
  },
  {
    path: '/top-menu',
    component: () => import('@/layouts/top-menu/Main.vue'),
    children: []
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Main.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      beforeRouteEnter(to, from, next) {
        const destination = {
          path: '/login',
          query: from.query,
          params: from.params
        }
        store.dispatch('auth/logout')
        next(destination)
      }
    }
  },
  {
    path: '/404',
    name: 'ErrorPage',
    component: () => import('@/views/error-page/Main.vue')
  },
  {
    path: '/403',
    name: 'ErrorPage403',
    component: () => import('@/views/error-page/Main403.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error-page/Main.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {left: 0, top: 0}
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/404', '/profile', '/logout', '/403']
  const path = to.path
  let pathNew = to.path
  const authRequired = !publicPages.includes(path)
  const loggedIn = store.state.auth.user
  pathNew = pathNew.substr(1)
  if (pathNew.includes('/')) {
    pathNew = pathNew.split('/')[0] + '/:id'
  }
  let perm = null
  routes[0].children.forEach(item => {
    if (item.path === pathNew) {
      perm = item.permission
    }
  })
  if (authRequired && !loggedIn) {
    next('/login')
  } else if (publicPages.includes(path) || path.includes('checkCertificate')) {
    next()
  } else {
    if (loggedIn && store.state.auth.user.permissions != null) {
      if (path === '/') {
        next()
      }
      const userPermissions = JSON.parse(
        JSON.stringify(store.state.auth.user.permissions)
      );
      if (checkArray(userPermissions, perm)) {
        next()
      } else {
        next({
          name: 'ErrorPage403'
        })
      }
    } else {
      next('/login')
    }
  }
})
const checkArray = function (userPermissions, permission) {
  let flagShow = false
  userPermissions.forEach(item => {
    if (item.name.includes('SUPER_ADMIN')) {
      flagShow = true
    } else if (item.name.includes(permission)) {
      flagShow = true
    }
  })

  return flagShow
}

export default router
