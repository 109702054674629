import $api from '@/http'

async function getList(pageNo, pageSize, filterModel) {
  return await $api.get('dictionaries/sectorCategory/list', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      keyword: filterModel.keyword
    }
  })
}

async function create(datas) {
  return await $api.post('dictionaries/sectorCategory/create', datas)
}

async function update(datas) {
  return await $api.patch('dictionaries/sectorCategory/update', datas)
}

export default {
  getList,
  create,
  update
}
