import $api from '@/http'
import axios from "axios";
import store from "@/store";

async function getUserRoles(pageNo, pageSize) {
  return await $api.get('userRole/list', {
    params: {
      pageNo,
      pageSize,
    }
  })
}

async function createUserRole({name, nameRu}) {
  return await $api.post('userRole/create', {
    name,
    nameRu
  })
}
async function updateUserRole({id, name, nameRu}) {
  return await $api.patch('userRole/update', {
    id,
    name,
    nameRu
  })
}

async function getPermissions() {
  return await $api.get('permissions')
}

async function getUserRoleById(id) {
  return await $api.get('userRole', {
    params: {
      id
    }
  })
}

async function updateUserRolePermissions(checkedPermissions, userRoleId) {
  return await $api.patch('userRole/updatePermissions', checkedPermissions, {
    params: {
      id: userRoleId
    }
  })
}

async function getServerTime() {
  let server = new Date()
    await axios.get(process.env.VUE_APP_API_URL+'servertime').then(response=> {
    server = response.data.serverTime
  })
  return server
}
const checkPermissions = (perms) => {
  const userPermissions = store.state.auth.user != null ? store.state.auth.user.permissions : null
  let isAccess = false
  userPermissions.forEach(item => {
    perms.forEach(item2 => {
      if (item.name === item2 || item.name === 'SUPER_ADMIN') {
        isAccess = true
      }
    })
  })
  return isAccess
}

export default {
  getUserRoles,
  createUserRole,
  updateUserRole,
  getPermissions,
  getUserRoleById,
  updateUserRolePermissions,
  getServerTime,
  checkPermissions
}
