/* eslint-disable */
import axios from 'axios';
import Jwt_decode from 'jwt-decode';
import {AdminService} from '@/services';
import store from '@/store';
import router from '@/router';
import {getItem} from '@/helpers/persistanceStorage';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});
let isRefreshing = false;
let refreshPromise = null;
let failedQueue = [];

const processQueue = (error, jwtToken = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(jwtToken);
    }
  });
  failedQueue = [];
};

const refreshToken = async () => {
  if (!refreshPromise) {
    const token = getItem('token');
    refreshPromise = new Promise(async (resolve, reject) => {
      try {
        await store.dispatch('auth/refreshToken', token);
        const jwtToken = getItem('jwtToken');
        processQueue(null, jwtToken);
        resolve(jwtToken);
      } catch (error) {

        if (error.response && error.response.status === 401) {
          // Logout user and redirect to login page
          await store.dispatch('auth/logout');
          router.push('/login');
          // Clear local storage
          localStorage.removeItem('user');
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('token');
        } else {
          processQueue(error);
          reject(error);
        }
      } finally {
        refreshPromise = null;
      }
    });
  }
  return refreshPromise;
};

api.interceptors.request.use(
  async config => {
    let jwtToken = getItem('jwtToken');
    if (jwtToken) {
      const decodedJWTExpDate = new Date(Jwt_decode(jwtToken).exp * 1000);
      const serverTime = await AdminService.getServerTime();
      const isJwtActive = decodedJWTExpDate >= new Date(serverTime);

      if (!isJwtActive) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const newToken = await refreshToken();
            jwtToken = newToken;
          } catch (error) {
            console.error('Failed to refresh token:', error);
          } finally {
            isRefreshing = false;
          }
        } else {
          await refreshPromise;
          jwtToken = getItem('jwtToken');
        }
      }

      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (err.response && err.response.status === 401) {
      // Logout user and redirect to login page
      await store.dispatch('auth/logout');
      router.push('/login');
      // Clear local storage
      localStorage.removeItem('user');
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('token');
    }
    return Promise.reject(err);
  }
);

export default api;
