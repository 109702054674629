import $api from '@/http'

async function getUsers(pageNo, pageSize, {
  firstName,
  lastName,
  patronymic,
  username
}) {
  return await $api.get('user/list', {
    params: {
      pageNo,
      pageSize,
      firstName,
      lastName,
      patronymic,
      username
    }
  })
}

async function createUser({
                            email,
                            firstName,
                            isActive,
                            lastName,
                            oblastId,
                            rayonId,
                            organisationId,
                            password,
                            patronymic,
                            phone,
                            userRoleId,
                            username
                          }) {
  return await $api.post('user/create', {
    email,
    firstName,
    isActive,
    lastName,
    oblastId,
    rayonId,
    organisationId,
    password,
    patronymic,
    phone,
    userRoleId,
    username
  })
}

async function updateUser({
                            id,
                            email,
                            firstName,
                            isActive,
                            lastName,
                            oblastId,
                            rayonId,
                            organisationId,
                            password,
                            patronymic,
                            phone,
                            userRoleId,
                            username
                          }) {
  return await $api.patch('user/update', {
    id,
    email,
    firstName,
    isActive,
    lastName,
    oblastId,
    rayonId,
    organisationId,
    password,
    patronymic,
    phone,
    userRoleId,
    username
  })
}

export default {
  getUsers,
  createUser,
  updateUser
}
