import $api from '@/http'

async function getList(pageNo, pageSize, filterModel) {
  return await $api.get('reference/list', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      keyword: filterModel.keyword,
      countryId: filterModel.countryId,
      originOfFundingId: filterModel.originOfFundingId
    }
  })
}

async function create(datas) {
  return await $api.post('reference/create', datas)
}

async function update(datas) {
  return await $api.patch('reference/update', datas)
}

async function getById(id) {
  return await $api.get('reference/getById/' + id)
}

async function downloadReference(id, number, isEn) {
  return await $api.get('reference/downloadReference', {
    params: {
      id: id,
      number: number,
      isEn: isEn
    },
    responseType: 'arraybuffer'
  })
}

export default {
  getList,
  create,
  update,
  getById,
  downloadReference
}
