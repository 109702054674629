/* eslint-disable */
import $api from '@/http'
import axios from "axios";

class AuthService {
  login(user) {
    return axios
      .post(process.env.VUE_APP_API_URL + 'authenticate', {
        headers: {
          'Content-Type': 'application/json'
        },
        username: user.username,
        password: user.password,
        device: user.device,
        ip: user.ip
      })
      .then(response => {
        return response.data
      })
  }

  refreshToken(token) {
    return axios
      .post(process.env.VUE_APP_API_URL + 'refreshToken', {
        token: token
      })
      .then(response => {
        return response.data
      })
  }

  logout(token) {
    return axios
      .post(process.env.VUE_APP_API_URL + 'logoutUser', {
        token: token
      })
      .then(response => {
        return response.data
      })
  }
}

export default new AuthService()
