import $api from '@/http'

async function getList(pageNo, pageSize, { keyword }) {
  return await $api.get('dictionaries/nationality/list', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      keyword: keyword
    }
  })
}

async function create(datas) {
  return await $api.post('dictionaries/nationality/create', datas)
}

async function update(datas) {
  return await $api.patch('dictionaries/nationality/update', datas)
}

export default {
  getList,
  create,
  update
}
