import $api from '@/http'

async function getList(pageNo, pageSize, sectorCategoryId, keyword ) {
  return await $api.get('dictionaries/sector/list', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      keyword: keyword,
      sectorCategoryId: sectorCategoryId
    }
  })
}

async function create(datas) {
  return await $api.post('dictionaries/sector/create', datas)
}

async function update(datas) {
  return await $api.patch('dictionaries/sector/update', datas)
}

export default {
  getList,
  create,
  update
}
