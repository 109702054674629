import * as types from './mutation-types'

const state = () => {
  return {
    darkMode: false,
    showVideoInstructionModal: false
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode,
  showVideoInstructionModal: state => state.showVideoInstructionModal
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  },
  setVideoInstructionModalShow({ commit }, showVideoInstructionModal) {
    commit(types.SET_VIDEO_INSTRUCTION_MODAL_SHOW, { showVideoInstructionModal })
    localStorage.setItem('showVideoInstructionModal', showVideoInstructionModal)
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode
  },
  [types.SET_VIDEO_INSTRUCTION_MODAL_SHOW](state, { showVideoInstructionModal }) {
    state.showVideoInstructionModal = showVideoInstructionModal
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
